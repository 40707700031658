import { createRouter, createWebHistory } from 'vue-router';

const router = createRouter({
    routes: [{
            path: '/',
            name: 'Home',
            component: () =>
                import ('@/page/Home'),
        },
        {
            path: '/mon-parcours',
            name: 'Apropos',
            component: () =>
                import ('@/page/Apropos'),
        },
        {
            path: '/contact-developpeur-wordpress-freelance',
            name: 'Contact',
            component: () =>
                import ('@/page/Contact'),
        },
        {
            path: "/:catchAll(.*)",
            name: "NotFound",
            component: () =>
                import ('@/page/NotFound'),
        }
    ],
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {
                top: 0,
                behavior: 'smooth'
            }
        }
    },
});

router.afterEach((to, from) => { // (to, from)
    let body = document.querySelector('body');
    body.classList.remove('vue--page--' + from.name);
    body.classList.add('vue--page--' + to.name);
});


export default router;