<template>
<header id="site-header" class="site-header" role="banner">
  <div v-if="header" class="section-inner header-wrapper">
    <div class="header-logo">
    <router-link to="/">
      <img class="site-logo" :src="header.site_logo" />
    
    </router-link>
    </div>
    <div class="header-navigation">
    <MenuSimple :Menu="header.main_menu" classMenu="main-menu"/>
    <MenuSocial :Menu="header.social_menu" classMenu="social-menu"/>
    </div>
    </div>
</header>
</template>

<script>
import MenuSimple from './MenuSimple.vue'
import MenuSocial from './MenuSocial.vue'

export default {
  name: 'Header',
  components: {
    MenuSimple,   
     MenuSocial,
  },
  props: {

  },
    data() {
      return {
        header: null,
      }
    },
    created() {
    this.fetchData()
  
  },
  methods: {
    fetchData() {
     /* fetch('https://25et7.fr/dev/2022/25et7/_init/header')*/
      fetch('https://25et7.fr/25et7/_init/header')
          .then(async response => { const data = await response.json();
         if (data) { this.header = data; }

         })
    },
  },
}
</script>
