<template>
<nav :class="classMenu" role="navigation">
      <ul>
      <li  v-for="link in Menu" :key="link.id">
          <a :href="link._url" v-html="link.title" target="_blank" class="link-icon"></a>
      </li>
    </ul>
</nav>
</template>

<script>
export default {
  name: 'MenuSocial',
  props: [
    'Menu', 'classMenu']
}
</script>
<style scoped>
.social-menu ul{
  display: flex;
  padding: 0;}
footer .social-menu ul{justify-content: flex-end;}
.social-menu li{ margin-left: 10px;}

@media screen and (max-width: 480px) {
	
}

</style>
