<template>
  <footer id="site-footer" role="contentinfo" class="footer-group"> 
    <div v-if="footer" class="section-inner">
      <div class="left-footer">
        <router-link to="/contact-developpeur-wordpress-freelance" class="link-footer"> Vous avez un projet Web ?</router-link>
      </div>

    <div class="right-footer">
      <span class="site-tile"  v-html="footer.site_title.replace(/(\\r)*\\n/g, '<br>')"></span>
      <MenuSimple :Menu="footer.main_menu" classMenu="main-menu"/>
      <MenuSocial :Menu="footer.social_menu" classMenu="social-menu"/>
     <span v-text="footer.mention_text" class="site-mention"></span>
    </div>
    </div>
    <div class="skew-back"></div>
  </footer>
</template>

<script>
import MenuSimple from './MenuSimple.vue'
import MenuSocial from './MenuSocial.vue'

export default {
  name: 'Footer',
  components: {   MenuSimple, MenuSocial},
  data() { return {  footer: null, } },
  created() { this.fetchData()  },
  methods: {
    fetchData() {
     /*  fetch('https://25et7.fr/dev/2022/25et7/_init/footer')*/
       fetch('https://25et7.fr/25et7/_init/footer')
        .then(async response => { const footer_data = await response.json();
         if (footer_data) { this.footer = footer_data; }  })
    },
  },
}

</script>
