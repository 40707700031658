<template>
<nav :class="classMenu" 	role="navigation"  >
  <ul>
      <li  v-for="link in Menu" :key="link.id">
          <router-link :to="link._url" v-html="link.title"></router-link>
      </li>
    </ul>
</nav>
</template>

<script>

export default {
  name: 'MenuSimple',
  props: [ 'Menu', 'classMenu']
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-menu a{
  font-family: 'Open Sans', sans-serif;
  color: #465054; ;}
.main-menu ul{padding: 0;margin-right: 20px; display: flex;}
.main-menu ul li:first-child{margin-right: 20px;}

footer .main-menu ul{margin-right: 0; flex-direction: column;}
footer .main-menu ul li:first-child{margin-right: 0;}


@media screen and (max-width: 480px) {

    .main-menu ul li:first-child {
        margin-right: auto;
    }}
</style>
