<template>

  <Header />
  <div id="primary" class="content-area">  

<main id="main" class="site-main" role="main" >
  <router-view v-slot="{Component}">  
    <transition name="fade" mode="out-in">  
      <component :is="Component" :key="$route.path" />
    </transition>
  </router-view>
</main>
  </div>

  <Footer />
</template>
 
<script>
import Header from './components/header.vue'
import Footer from './components/footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer }
}
</script>

<style>

</style>
